<script>
import Layout from "../../layouts/main";
import TradeServiceApi from "@/service/api/trade";
import Progress from "@/service/transactionType";
export default {
  components: { Layout },
  data() {
    return {
      tradeData: null,
      fields: [
        { key: "id", sortable: false },
        { key: "giver", sortable: false },
        { key: "receiver", sortable: false },
        { key: "giver_progress", sortable: false },
        { key: "receiver_progress", sortable: false },
        { key: "updated_at", sortable: false },
        { key: "created_at", sortable: false },
        { key: "status", sortable: false },
      ],
      progress: Progress.trade,
      tradeSelected: null,
    };
  },
  computed: {
    processData() {
      const item = this.tradeSelected;
      if (item) {
        const giver_size = item.trade_items.map((size) => {
          return size.giver_size;
        });
        const receiver_size = item.trade_items.map((size) => {
          return size.receiver_size;
        });

        const formattedData = {
          id: item.id,
          created_at: item.created_at,
          giver_amount: item.giver_amount,
          receiver_amount: item.receiver_amount,
          status: item.status,
          receiver_size: receiver_size.filter((item) => item),
          giver_size: giver_size.filter((item) => item),
        };
        return formattedData;
      } else {
        return {};
      }
    },
  },
  mounted() {
    this.loadTradeData();
  },
  methods: {
    loadTradeData() {
      TradeServiceApi.getAllActiveTrade().then((response) => {
        if (response.data.success) {
          this.tradeData = response.data.trades.data;
        }
      });
    },

    progressLabel(progress_id) {
      const label = this.progress[progress_id].label;
      const status = this.progress[progress_id].status;
      const color = this.progress[progress_id].color;
      const progress = `${label}<br/>
      <div style="background-color:${color};font-size:12px;color:white;display:inline-block;padding:2px 5px;border-radius:5px;">${status}</div>`;
      return progress;
    },
    transactionClicked(val) {
      this.tradeSelected = val;
      this.$refs.TradeInProgress.show();
      console.log(val);
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tradeData"
                :fields="fields"
                responsive="sm"
                @row-clicked="transactionClicked"
                selectable
              >
                <template v-slot:cell(giver_progress)="row">
                  <div v-html="progressLabel(row.value)"></div>
                </template>
                <template v-slot:cell(receiver_progress)="row">
                  <div v-html="progressLabel(row.value)"></div>
                </template>
                <template v-slot:cell(created_at)="row">
                  <div>
                    {{ moment(row.value).format("MMMM Do YYYY, h:mm:ss a") }}
                  </div>
                </template>
                <template v-slot:cell(updated_at)="row">
                  <div>
                    {{ moment(row.value).format("MMMM Do YYYY, h:mm:ss a") }}
                  </div>
                </template>
                <template v-slot:cell(giver)="row">
                  <div>{{ row.value.name }}</div>
                  <div>{{ row.value.email }}</div>
                </template>
                <template v-slot:cell(receiver)="row">
                  <div>{{ row.value.name }}</div>
                  <div>{{ row.value.email }}</div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="TradeInProgress"
      id="TradeInProgress"
      hide-footer
      hide-title
      centered
      size="xl"
    >
      <template #modal-header>
        <div class="mx-auto">
          <h5>Trade in Progress</h5>
        </div>
      </template>
      <div class="trade-card-details">
        <div class="text-center m-2">
          {{ moment(processData.created_at).format("MMMM Do YYYY") }}
          <div>
            {{ moment(processData.created_at).format("h:mm a") }}
          </div>
        </div>
        <div class="row m-4">
          <div class="col-sm-5">
            <b-carousel
              :id="'carousel' + processData.id"
              :interval="4000"
              indicators
              class="ml-4 mr-4"
            >
              <!-- Text slides with image -->
              <b-carousel-slide
                v-for="(giver, index) in processData.giver_size"
                :key="index"
                :img-src="giver.product.image"
              >
                <div class="trade-card-details-text">
                  <div>
                    {{ giver.product.name }}
                  </div>
                  <div style="color:gray">
                    {{ giver.size }}
                  </div>
                </div>
              </b-carousel-slide>
            </b-carousel>
          </div>
          <div class="col-sm-2">
            <img
              class="mt-4"
              src="@/assets/images/arrow.png"
              alt=""
              style="max-width:80px;margin:auto;display:block;padding:10px;"
            />
            <div style="width:100px;margin:auto">
              <div
                style="font-size:12px;margin-top:10px;color:green;"
                v-if="processData.giver_amount"
              >
                + AED {{ processData.giver_amount }}
              </div>
              <div
                style="font-size:12px;margin-top:10px;color:red;"
                v-if="processData.receiver_amount"
              >
                - AED {{ processData.receiver_amount }}
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <b-carousel
              :id="'carousel-receiver' + processData.id"
              :interval="4000"
              indicators
              class="ml-4 mr-4"
            >
              <!-- Text slides with image -->
              <b-carousel-slide
                v-for="(receiver, index) in processData.receiver_size"
                :key="index"
                :img-src="receiver.product.image"
              >
                <div class="trade-card-details-text">
                  <div>
                    {{ receiver.product.name }}
                  </div>
                  <div style="color:gray">
                    {{ receiver.size }}
                  </div>
                </div>
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="tradeGiver" v-if="tradeSelected">
            <div class="trade-user-details">
              <div class="row">
                <div style="display:inline-block;margin-left:18px;">
                  <img
                    height="65"
                    v-if="tradeSelected.giver.image"
                    :src="tradeSelected.giver.image"
                    alt=""
                    srcset=""
                    width="65"
                    class="rounded-circle mt-2"
                  />
                  <img
                    v-else
                    height="65"
                    width="65"
                    src="@/assets/images/no-user.png"
                    alt=""
                    class="rounded-circle mt-2"
                    srcset=""
                  />
                </div>
                <div style="margin-left:10px;margin-top:10px;">
                  <div>
                    Name: {{ tradeSelected.giver.name }}
                    <div>Email : {{ tradeSelected.giver.email }}</div>
                    <div>
                      Amount :
                      {{
                        tradeSelected.giver_amount
                          ? tradeSelected.giver_amount
                          : 0
                      }}
                    </div>
                    <div>Amount Due : {{ tradeSelected.giver_amount_due }}</div>
                    <div style="font-weight:900;">
                      Giver
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="tradeReceiver" v-if="tradeSelected">
            <div class="trade-user-details">
              <div class="row">
                <div style="display:inline-block;margin-left:18px;">
                  <img
                    v-if="tradeSelected.receiver.image"
                    height="65"
                    width="65"
                    :src="tradeSelected.receiver.image"
                    alt=""
                    class="rounded-circle mt-2"
                    srcset=""
                  />
                  <img
                    v-else
                    height="65"
                    width="65"
                    src="@/assets/images/no-user.png"
                    alt=""
                    class="rounded-circle mt-2"
                    srcset=""
                  />
                </div>
                <div style="margin-left:10px;margin-top:10px;">
                  <div>
                    Name : {{ tradeSelected.receiver.name }}
                    <div>Email : {{ tradeSelected.receiver.email }}</div>
                    <div>
                      Amount :
                      {{
                        tradeSelected.receiver_amount
                          ? tradeSelected.receiver_amount
                          : 0
                      }}
                    </div>
                    <div>
                      Amount Due : {{ tradeSelected.receiver_amount_due }}
                    </div>
                    <div style="font-weight:900;">
                      Receiver
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<style>
.tradeGiver {
  height: 300px;

  margin: 5px;
}

.tradeReceiver {
  height: 300px;

  margin: 5px;
}

.trade-card-details {
  transition: 0.3s;
  margin: 5px;
}
.trade-card-details-text :first-child {
  font-weight: 100;
  font-size: 14px;
}
.trade-card-details ol.carousel-indicators {
  bottom: 0px;
}
.trade-card-details .carousel-indicators li {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #000;
}
.trade-card-details .carousel-caption {
  bottom: -95px;
  position: absolute;
  color: #000;
}

.trade-card-details .carousel-inner {
  height: 290px;
}
.trade-card-details .carousel-item img {
  height: 150px;
  width: 180px !important;
  margin: auto;
}
.tradeUserTitle {
  text-align: center;
  font-size: 16px;
  padding: 5px;
  width: 100px;
  margin: auto;
}
.trade-user-details {
  background-color: #f1f1f1;
  border-radius: 30px;
  height: 80px;
}
</style>
